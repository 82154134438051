import React, { Suspense, useEffect, useState, lazy } from 'react'
import useApiRequest from '@mbs-dev/api-request';
import Loading from '../../components/Loading/Loading';
import { Helmet } from 'react-helmet-async';
import AOS from 'aos';
import 'aos/dist/aos.css';
import { useSliderContext } from '../../contexts/SliderContext';
import TopHeader from '../../components/TopHeader/TopHeader';
import Navbar from '../../components/Navbar/Navbar';
import Marques from '../../components/Marques/Marques';
import PopulareProducts from '../../components/PopulareProducts/PopulareProducts';
import PopularePacks from '../../components/PopularePacks/PopularePacks';
import CategoriesOld from '../../components/Categories/CategoriesOld';
import Footer from '../../components/Footer/Footer';
import HomeCarousel from '../../components/Carousel/HomeCarousel/HomeCarousel';

const Home: React.FC = () => {
    const [isLoading, setIsLoading] = useState(true);
    const { apiRequest } = useApiRequest()
    const { sliders, isFetching } = useSliderContext();

    useEffect(() => {
        setTimeout(() => {
            setIsLoading(false);
        }, 1300);
    }, []);

    useEffect(() => {
        AOS.init();
    }, [])


    return (

        <>
            <Helmet>
                <title>HBeauty - Votre atout beauté</title>
            </Helmet>
            {/* {isLoading ? (
                <Loading />
            ) : ( */}
                {/* <Suspense fallback={<Loading />}> */}
                    <TopHeader />
                    <Navbar />
                    <HomeCarousel items={sliders} isLoading={isFetching} />
                    {/* <Marques /> */}
                    {/* <PopulareProducts /> */}
                    {/* <PopularePacks /> */}
                    <div data-aos="fade-up" data-aos-duration="2000">
                        <Marques />
                    </div>
                    <div data-aos="fade-up" data-aos-duration="2000">
                        <PopulareProducts />
                    </div>
                    <div data-aos="fade-up" data-aos-duration="2000">
                        <PopularePacks />
                    </div>
                    <div data-aos="fade-up" data-aos-duration="2000">
                        <CategoriesOld />
                    </div>
                    <Footer />
                {/* </Suspense> */}
            {/* )} */}
        </>
    )
}

export default Home;