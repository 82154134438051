import React, { useContext } from 'react'
import { EmblaOptionsType } from 'embla-carousel'
// import { DotButton, useDotButton } from './EmblaCarouselDotButton'
import {
  PrevButton,
  NextButton,
  usePrevNextButtons
} from './EmblaCarouselArrowButtons'
import useEmblaCarousel from 'embla-carousel-react'
import { Link } from 'react-router-dom'
import { CategorieProps } from '../../../helpers/Props'
import { categorieImgUrl } from '../../../helpers/ApiRequest'
import './CategoryEmblaCarousel.css'
import { WindowWidthContext } from '../../../contexts/WindowWidthProvider';

type PropType = {
  categories: CategorieProps[]
  options?: EmblaOptionsType
}

const CategoryEmblaCarousel: React.FC<PropType> = (props) => {
  const { categories, options } = props
  const [emblaRef, emblaApi] = useEmblaCarousel(options)
  const { windowWidth } = useContext(WindowWidthContext)

  //   const { selectedIndex, scrollSnaps, onDotButtonClick } =
  //     useDotButton(emblaApi)

  const {
    prevBtnDisabled,
    nextBtnDisabled,
    onPrevButtonClick,
    onNextButtonClick
  } = usePrevNextButtons(emblaApi)

  return (
    <section className="embla max-w-1700px">
      <div className="embla__viewport position-relative" ref={emblaRef}>
        <div className="embla__container">
          {categories.map((item) => (
            <div className="embla__slide" key={item.id}>
              <Link key={item.id} to={`/boutique/categorie/${item.slug}`} className="nav-link">
                <img
                  className="embla__slide-img radius-10px"
                  src={`${categorieImgUrl}/${item?.image}`}
                  alt={item.title}
                />
              </Link>
              <div className="text-center">
                <p className={`${windowWidth < 644 ? 'mt-1 mb-1rem' : 'mb-3rem'} fs-15px ff-0 fw-600`}>{item.title}</p>
              </div>
            </div>
          ))}
        </div>

        <div className="embla__controls d-non">
          <div className="embla__buttons">
            <PrevButton onClick={onPrevButtonClick} disabled={prevBtnDisabled} />
            <NextButton onClick={onNextButtonClick} disabled={nextBtnDisabled} />
          </div>

          {/* <div className="embla__dots">
          {scrollSnaps.map((_, index) => (
            <DotButton
              key={index}
              onClick={() => onDotButtonClick(index)}
              className={'embla__dot'.concat(
                index === selectedIndex ? ' embla__dot--selected' : ''
              )}
            />
          ))}
        </div> */}
        </div>
      </div>


    </section>
  )
}

export default CategoryEmblaCarousel
