import React, { useEffect, useState } from "react";
import './CircularMultiCarousel.css'
import { Link } from "react-router-dom";
import { apiUrl, handleImageLinkDrage } from "../../../helpers/ApiRequest";
import { CategorieProps } from "../../../helpers/Props";
import { FrCustomeErrorNorify } from "../../../helpers/Toastify";
import MultiCarousel from "../MultiCarousel/MultiCarousel";
import useApiRequest from "@mbs-dev/api-request";

interface CircularMultiCarouselProps {
    routeUrl: string
    slug: string,
    uploadUrl: string
}

const CircularMultiCarousel: React.FC<CircularMultiCarouselProps> = ({ routeUrl, slug, uploadUrl }) => {

    const [subCategories, setSubCategories] = useState<CategorieProps[]>([]);
    const { apiRequest } = useApiRequest();

    const fetchData = async () => {
        try {
            const response = await apiRequest({
                route: `${apiUrl}/${routeUrl}/${slug}`,
                method: 'GET',
            });

            if (response.status === 200) {
                setSubCategories(response.data.subcategories);
            }

        } catch (error) {
            FrCustomeErrorNorify()
        }
    };

    useEffect(() => {
        fetchData();
    }, [slug]);

    const content = subCategories.map((item: any, index: number) => (
        <div className="logo-gallery-content d-flex align-items-center justify-content-center mxc-5" key={index}>
            <div className="logo-gallery">
                <Link to={`/boutique/categorie/${slug}/${item.slug}`} onDragStart={handleImageLinkDrage} className='nav-link w-100 h-100'>
                    <img
                        src={`${process.env.REACT_APP_API_UPLOAD}/${uploadUrl}/${item?.image}`}
                        className="logo-gallery-img"
                        alt={item?.title}
                        onDragStart={handleImageLinkDrage}
                    />
                </Link>

            </div>
            <div className="logo-gallery-title mt3 d-non align-items-center justify-content-center">
                <p className='logo-gallery-title-p ff-0 fw-600'>{item?.title}</p>
            </div>

        </div>
    ))


    return (
        <div className="sub-categorie-carousel">
            {
                subCategories && (
                    < MultiCarousel data={content} isInfinite={false} />
                )
            }
        </div>
    );
};

export default CircularMultiCarousel;