import React, { useState, lazy, useContext } from 'react';
import { HiOutlineMinusSm } from 'react-icons/hi'
import { ContextProps, WindowWidthContext } from '../../contexts/WindowWidthProvider';
import Slider from '@mui/material/Slider';
import { Helmet } from 'react-helmet-async';
import LoadingMore from '../../components/Loading/LoadingMore';
import { usePacksContext } from '../../contexts/PacksContext';
import PackCard from '../../components/PackCard/PackCard';
import emptyBox from '../../../assets/images/bootique/empty-box.png'
import { FaFilter } from 'react-icons/fa'
import { BsGridFill } from 'react-icons/bs'
import { CiGrid2H } from 'react-icons/ci'
import './BoutiqueStyle.css'
import LoadingData from '../../components/Loading/LoadingData';
import TopHeader from '../../components/TopHeader/TopHeader';
import Navbar from '../../components/Navbar/Navbar';
import HeaderContainer from '../../components/HeaderContainer/HeaderContainer';
import Footer from '../../components/Footer/Footer';

// const Navbar = lazy(() => import('../../components/Navbar/Navbar'));
// const TopHeader = lazy(() => import('../../components/TopHeader/TopHeader'));
// const HeaderContainer = lazy(() => import('../../components/HeaderContainer/HeaderContainer'));
// const Footer = lazy(() => import('../../components/Footer/Footer'));

function valuetext(value: number) {
  return `${value}`;
}

const PackBoutique: React.FC = () => {

  // Global
  const { windowWidth } = useContext<ContextProps>(WindowWidthContext)
  // const [isLoading, setIsLoading] = useState(false);
  const { packs,
    fetchNextPage,
    isFetchingNextPage,
    minPrice,
    maxPrice,
    totalPacks,
    reactQueryLoadingData } = usePacksContext();

  const [isGrid, setIsGrid] = useState(true);
  const [isFilter, setIsFilter] = useState(true);


  const [filteredPrice, setFilteredPrice] = useState<number[]>([minPrice, maxPrice]);
  const [sortBy, setSortBy] = useState('');
  const [sortOrder, setSortOrder] = useState<'asc' | 'desc'>('asc');
  const handlePriceChange = (event: Event, newValue: number | number[]) => {
    setFilteredPrice(newValue as number[]);
  };


  let filteredData;
  filteredData = [...packs];

  filteredData = filteredData.filter((pack) => {
    const price = pack.tarif_regulier;
    return price >= filteredPrice[0] && price <= filteredPrice[1];
  });

  if (sortBy === 'prix-croissant') {
    filteredData = filteredData.sort((a, b) => a.tarif_regulier - b.tarif_regulier);
  } else if (sortBy === 'prix-decroissant') {
    filteredData = filteredData.sort((a, b) => b.tarif_regulier - a.tarif_regulier);
  } else if (sortBy === 'date-croissant') {
    filteredData.sort((a: any, b: any) => new Date(a.dateFin).getTime() - new Date(b.dateFin).getTime());
  } else if (sortBy === 'date-decroissant') {
    filteredData.sort((a: any, b: any) => new Date(b.dateFin).getTime() - new Date(a.dateFin).getTime());
  }

  const handleSortChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedSortBy = event.target.value;
    setSortBy(selectedSortBy);

    if (selectedSortBy === 'date-croissant') {
      setSortOrder('asc');
    } else if (selectedSortBy === 'date-decroissant') {
      setSortOrder('desc');
    }
  };

  const handleRadioSortChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const selectedSortBy = event.target.value;
    setSortBy(selectedSortBy);

    if (selectedSortBy === 'date-croissant') {
      setSortOrder('asc');
    } else if (selectedSortBy === 'date-decroissant') {
      setSortOrder('desc');
    }
  };


  return (
    <>
      <Helmet>
        <title>HBeauty - Packs</title>
      </Helmet>
      <TopHeader />
      <Navbar />
      <HeaderContainer className="fw-bold mt-5 mb-5 contact-title" title="PACKS" />

      <div className={`boutique ${isGrid ? 'isgrid' : 'isrow'}`}>
        <div className="product-constainere containerr filter- boutique- container d-flexjustify-content-center">
          <div className="row d-flex justify-content-center">
            {
              packs && (
                <div className="col-xl-3 col-lg-4 col-md-4 col-sm-11 col-11 mt-2 mb-4 pb-4 filter ">
                  <div className={`container ${isFilter && windowWidth < 768 ? '' : 'mt-5'}`}>

                    <div className={`row ${isFilter && windowWidth < 768 ? 'd-none' : ''}`}>
                      <div className="col-xl-12 col-lg-12 col-md-12 col-12  ">

                        <div className="filter-title filter-sort d-none">
                          <span className='filter-result' >Filtrer le résultat:</span>
                          <select value={sortBy} className='form-control select-option mt-3' onChange={handleSortChange}>
                            {/* <option value=""></option> */}
                            <option value="default">Défaut</option>
                            <option value="prix-croissant">Prix croissant</option>
                            <option value="prix-decroissant">Prix décroissant</option>
                            <option value="date-croissant">Date d'expiration croissant</option>
                            <option value="date-decroissant">Date d'expiration décroissant</option>
                            <option value="plus-recent-au-plus-ancien">Plus récent au plus ancien</option>
                          </select>
                        </div>
                        {/* <hr className='hr-tag mt-3' /> */}

                        {/* =============================================================== */}

                        <div className="filter-title filter-sort">
                          <span className='filter-result'>Filtrer le résultat:</span>
                          <div className="radio-buttons mt-3 ms-2">
                            <div className="form-check mb-3">
                              <label htmlFor='df' className='ff-0 fw-600 dblock filter-label'>Défaut</label>
                              <input
                                type="radio"
                                id='df'
                                className='me-2 fs-5 form-check-input shopping-form-check-input'
                                name="filterOption"
                                value="default"
                                checked={sortBy === 'default'}
                                onChange={handleRadioSortChange}
                              />
                            </div>


                            <div className="form-check mb-3">
                              <label htmlFor='pc' className='ff-0 fw-600 dblock filter-label'>Prix croissant</label>
                              <input
                                type="radio"
                                id='pc'
                                className='me-2 fs-5 form-check-input shopping-form-check-input'
                                name="filterOption"
                                value="prix-croissant"
                                checked={sortBy === 'prix-croissant'}
                                onChange={handleRadioSortChange}
                              />
                            </div>
                            <div className="form-check mb-3">
                              <label htmlFor='pd' className='ff-0 fw-600 dblock filter-label'>Prix décroissant</label>
                              <input
                                type="radio"
                                id='pd'
                                className='me-2 fs-5 form-check-input shopping-form-check-input'
                                name="filterOption"
                                value="prix-decroissant"
                                checked={sortBy === 'prix-decroissant'}
                                onChange={handleRadioSortChange}
                              />
                            </div>
                            <div className="form-check mb-3">
                              <label htmlFor='dc' className='ff-0 fw-600 dblock filter-label'>Date d'expiration croissant</label>
                              <input
                                type="radio"
                                id='dc'
                                className='me-2 fs-5 form-check-input shopping-form-check-input'
                                name="filterOption"
                                value="date-croissant"
                                checked={sortBy === 'date-croissant'}
                                onChange={handleRadioSortChange}
                              />
                            </div>


                            <div className="form-check">
                              <label htmlFor='dd' className='ff-0 fw-600 dblock filter-label'>Date d'expiration décroissant</label>
                              <input
                                type="radio"
                                id='dd'
                                className='me-2 fs-5 form-check-input shopping-form-check-input'
                                name="filterOption"
                                value="date-decroissant"
                                checked={sortBy === 'date-decroissant'}
                                onChange={handleRadioSortChange}
                              />
                            </div>

                          </div>
                        </div>

                        <hr className='hr-tag mt-3' />

                      </div>
                      {/* End Result Filter */}

                      {/* Start Price Filter */}
                      <div className="col-xl-12 col-lg-12 col-md-12 col-12 ">
                        <div className="filter-title filter-price">
                          FILTRER PAR TARIF
                        </div>
                        <div className="price-filter mt-4">
                          <div className="row">
                            <div className="col-12">
                              <Slider
                                getAriaLabel={() => 'Temperature range'}
                                value={filteredPrice}
                                onChange={handlePriceChange}
                                valueLabelDisplay="auto"
                                getAriaValueText={valuetext}
                                max={maxPrice}
                                min={20}
                              />
                            </div>
                            <div className="col-12">
                              <div className="row">
                                <div className="col-8">
                                  <div className="price-values">
                                    <span className='filter-price-value'>PRIX : {filteredPrice[0]} DH <HiOutlineMinusSm /> {filteredPrice[1]} DH</span>
                                  </div>
                                </div>

                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      {/* End Price Filter */}

                    </div>

                    <div className={`row  ${isFilter ? 'm-filter m-filter-rowx' : 'm-filter-row-active'} ${windowWidth > 768 ? 'd-none' : ''}`}>
                      {!isFilter ? <hr className='hr-tag mt-3' /> : ''}
                      <div className={`col-6 text-start ${isFilter ? 'm-filter-icon' : 'm-filter-icon-active'}`}>

                        <button className='btn hb-small-btn hb-danger-btn me-2' onClick={() => setIsFilter(!isFilter)}>
                          <FaFilter className='hb-icon hb-danger-icon' />
                        </button>

                      </div>

                      <div className={`col-6 text-end fix-width ${windowWidth > 576 ? 'd-none' : ''} ${isFilter ? '' : 'm-grid-filter'}`}>
                        <button className='btn hb-small-btn hb-primary-btn me-2 ' onClick={() => setIsGrid(false)}>
                          <CiGrid2H className='hb-icon hb-primary-icon' />
                        </button>
                        <button className='btn hb-small-btn hb-primary-btn me-2' onClick={() => setIsGrid(true)}>
                          <BsGridFill className='hb-icon hb-primary-icon' />
                        </button>
                      </div>


                    </div>

                  </div>
                </div>
              )
            }

            {
              reactQueryLoadingData ?
                <div className="col-xl-9 col-lg-8 col-md-8 col-sm-12 col-12  loading-data">
                  <span className='loading-data-text text-center clr-dark ff-0 fw-600 fs-5 mt-2'>Chargement des packs...</span>
                  <LoadingData />
                </div>
                :
                <div className={`col-xl-9 col-lg-8 col-md-8 col-sm-12 col-12 d-flex justify-content-${windowWidth < 768 && !isGrid ? 'center' : 'start'} flex-wrap`}>
                  {
                    filteredData.length > 0
                      ?
                      filteredData.map((item, index) => (
                        <PackCard key={item.title} item={item} isGrid={isGrid} />
                      ))
                      :
                      // <div className="left-50 pos-r">
                      <>
                        {/* < LoadingMore /> */}
                        <div className="col-12 d-flex justify-content-center align-items-center col-">
                          <img src={emptyBox} alt="empty box" className='shopping-card-empty-image' />
                        </div>
                        <div className="col-12 d-flex justify-content-center align-items-center col-">
                          <div className="clr-dark fw-600 ff-0 fs-4 mn-14">Aucun Produits</div>
                        </div>
                      </>
                    // </div>

                  }
                  <div className="col-12 d-flex justify-content-center">
                    <>
                      {filteredData.length > 0 && filteredData.length < totalPacks && !isFetchingNextPage && (
                        <button className="btn-loading mt-5" onClick={fetchNextPage} >
                          Chargement Plus
                        </button>
                      )}
                      {
                        isFetchingNextPage && (
                          < LoadingMore />
                        )
                      }
                    </>
                  </div>

                </div>
            }

          </div>
        </div>
      </div >

      <Footer />
    </>
  );
};

export default PackBoutique;