import { FC, lazy, useState, useContext, useEffect } from 'react';
import { Link, useLocation, useParams } from 'react-router-dom';
import { HiOutlineMinusSm } from 'react-icons/hi'
import { useProductsContext } from '../../contexts/ProductContext';
import { useMarquesContext } from '../../contexts/MarquesContext';
import { useCategoriesContext } from '../../contexts/CategoriesContext';
import { WindowWidthContext } from '../../contexts/WindowWidthProvider';
import { Helmet } from 'react-helmet-async';
import { ProductProps } from '../../helpers/Props';
import useApiRequest from '@mbs-dev/api-request';
import emptyBox from '../../../assets/images/bootique/empty-box.png'
import Slider from '@mui/material/Slider';
import Modal from '../../components/DetailsProductModal/ModalContent';
import LoadingMore from '../../components/Loading/LoadingMore';
import { FaFilter } from 'react-icons/fa'
import { BsGridFill } from 'react-icons/bs'
import { CiGrid2H } from 'react-icons/ci'
import { MdExpandMore } from 'react-icons/md'
import CircularMultiCarousel from '../../components/Carousel/CircularMultiCarousel/CircularMultiCarousel';
import LoadingData from '../../components/Loading/LoadingData';
import { FrCustomeErrorNorify } from '../../helpers/Toastify';
import { useProductIds } from '../../hooks/useProductIds';
import CategorieLogoCarousel from '../../components/Carousel/CategorieLogoCarousel/CategorieLogoCarousel';
import TopHeader from '../../components/TopHeader/TopHeader';
import Navbar from '../../components/Navbar/Navbar';
import Footer from '../../components/Footer/Footer';
import './BoutiqueStyle.css'
import CategorySwiper from '../../components/Carousel/CategorySwiper/CategorySwiper';
import CategoryEmblaCarousel from '../../components/Carousel/CategoryEmblaCarousel/CategoryEmblaCarousel';
import { EmblaOptionsType } from 'embla-carousel'
import { apiUrl } from '../../helpers/ApiRequest';
import ProductCard from '../../components/ProductCard/ProductCard';
import TopImage from '../../components/TopImage/TopImage';
// Lazy Import
// const TopImage = lazy(() => import('../../components/TopImage/TopImage'));
// const ProductCard = lazy(() => import('../../components/ProductCard/ProductCard'));

function valueText(value: number) {
  return `${value}`;
}

const Boutique: FC = () => {

  // Global
  const { apiRequest } = useApiRequest();
  const { windowWidth } = useContext(WindowWidthContext)
  const [isLoadingData, setIsLoadingData] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [isGrid, setIsGrid] = useState(true);
  const [isFilter, setIsFilter] = useState(true);
  const [isSub, setIsSub] = useState(false);
  const { productIds, isProductIds } = useProductIds();

  const location = useLocation();
  const path = location.pathname;
  const pattern = /^\/boutique\/categorie\/[a-z0-9-]+\/[a-z0-9-]+$/;
  const isMatch = pattern.test(path);

  const segments = path.split('/');
  const parentSlug = segments[3];

  // Product
  const [showModal, setShowModal] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const {
    products,
    fetchNextPage,
    isFetchingNextPage,
    minPrice,
    maxPrice,
    totalProducts,
    reactQueryLoadingData } = useProductsContext();

  const [productsBy, setProductsBy] = useState<ProductProps[]>([]);
  const [loadingMoreData, setloadingMoreData] = useState(false);

  // Filter
  const [isFilVisible, setIsFilVisible] = useState(false);
  const { marques } = useMarquesContext();
  const { categories } = useCategoriesContext();
  const { marqueId, categorieId } = useParams();
  const [maxResultByMarque, setMaxResultByMarque] = useState(20);
  const [maxResultByCategory, setMaxResultByCategory] = useState(20);
  const [totalByMarque, setTotalByMarque] = useState(0);
  const [totalByCategory, setTotalByCategory] = useState(0);
  const [sortByDate, setSortByDate] = useState("");
  const [previousCategorie, setPreviousCategorie] = useState("");
  const [previousMarque, setPreviousMarque] = useState("");


  useEffect(() => {
    if (path !== '') {

      if (isMatch) {
        setIsSub(true)
      }
      else {
        setIsSub(false)
      }
    }
  }, [path])


  const handleByMarqueIncrement = () => {
    setMaxResultByMarque(maxResultByMarque + 20);
    setloadingMoreData(true);
  };

  const handleByCategoryIncrement = () => {
    setMaxResultByCategory(maxResultByCategory + 20);
    setloadingMoreData(true);
  };

  const openModal = (product: any) => {
    setSelectedProduct(product);
    setShowModal(true);
  };

  const closeModal = () => {
    setShowModal(false);
  };

  const fetchData = async () => {
    try {

      if (marqueId) {
        if (marqueId !== previousMarque) {
          setMaxResultByMarque(20)
        }
        if (maxResultByMarque === 20 || marqueId !== previousMarque) {
          setIsLoadingData(true)
        }
        const response = await apiRequest({
          route: `${apiUrl}/by-marque/${marqueId}/${maxResultByMarque}`,
          method: 'GET',
        });

        const totalResponse = await apiRequest({
          route: `${apiUrl}/total-products-bymarque/${marqueId}`,
          method: 'GET',
        });

        if (totalResponse.status === 200) {
          setTotalByMarque(totalResponse.data[0].totalProducts)
        }

        if (response.status === 200) {
          setProductsBy(response.data)
          setloadingMoreData(false);
          setIsLoading(true)
          setIsLoadingData(false)
          setPreviousMarque(marqueId)
        }

      }


      if (categorieId) {
        if (categorieId !== previousCategorie) {
          setMaxResultByCategory(20)
        }
        if (maxResultByCategory === 20 || categorieId !== previousCategorie) {
          setIsLoadingData(true)
        }

        const response = await apiRequest({
          route: `${apiUrl}/by-categorie/${categorieId}/${maxResultByCategory}`,
          method: 'GET',
        });

        const totalResponse = await apiRequest({
          route: `${apiUrl}/total-products-bycategory/${categorieId}`,
          method: 'GET',
        });

        if (totalResponse.status === 200) {
          setTotalByCategory(totalResponse.data[0].totalProducts)
        }

        if (response.status === 200) {
          setProductsBy(response.data)
          setloadingMoreData(false);
          setIsLoading(true)
          setIsLoadingData(false)
          setPreviousCategorie(categorieId)
        }

      }
    } catch (error) {
      FrCustomeErrorNorify()
    }
  };

  useEffect(() => {
    fetchData()
  }, [maxResultByMarque, maxResultByCategory, marqueId, categorieId])


  // const [filteredPrice, setFilteredPrice] = useState<number[]>([minPrice, maxPrice]);
  const [filteredPrice, setFilteredPrice] = useState<number[]>([minPrice, 2000]);
  const [sortBy, setSortBy] = useState('');

  useEffect(() => {
    if (maxPrice) {
      setFilteredPrice([minPrice, maxPrice])
    }
  }, [maxPrice])

  const handlePriceChange = (event: Event, newValue: number | number[]) => {
    setFilteredPrice(newValue as number[]);
  };

  let filteredData = [...products];

  if (marqueId) {
    filteredData = [...productsBy];
    if (sortBy === 'prix-croissant') {
      filteredData = filteredData.sort((a, b) => a.tarif_regulier - b.tarif_regulier);
    } else if (sortBy === 'prix-decroissant') {
      filteredData = filteredData.sort((a, b) => b.tarif_regulier - a.tarif_regulier);
    }

  }

  if (categorieId) {
    filteredData = [...productsBy];
    if (sortBy === 'prix-croissant') {
      filteredData = filteredData.sort((a, b) => a.tarif_regulier - b.tarif_regulier);
    } else if (sortBy === 'prix-decroissant') {
      filteredData = filteredData.sort((a, b) => b.tarif_regulier - a.tarif_regulier);
    }

  }


  filteredData = filteredData.filter((product) => {
    const price = product.tarif_regulier;
    return price >= filteredPrice[0] && price <= filteredPrice[1];
  });

  if (sortBy === 'prix-croissant') {
    filteredData = filteredData.sort((a, b) => a.tarif_regulier - b.tarif_regulier);
  } else if (sortBy === 'prix-decroissant') {
    filteredData = filteredData.sort((a, b) => b.tarif_regulier - a.tarif_regulier);
  }

  if (sortBy === "date-asc" || sortBy === "date-desc") {
    filteredData = filteredData.sort((a, b) => {
      // Convert creationDate strings to Date objects
      const dateA = new Date(a.creationDate) as any;
      const dateB = new Date(b.creationDate) as any;

      if (sortByDate === "asc") {
        return dateA - dateB;
      } else if (sortByDate === "desc") {
        return dateB - dateA;
      }

      return 0;
    });
  }

  const handleSortChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedSortOption = event.target.value;

    setSortBy(selectedSortOption);

    if (selectedSortOption === "date-asc") {
      setSortByDate("asc");
    } else if (selectedSortOption === "date-desc") {
      setSortByDate("desc");
    }

    setIsFilter(true);
  };

  const productContent = (
    <div className={`col-xl-9 col-lg-8 col-md-8 col-sm-12 col-12 d-flex d-non justify-content-${windowWidth < 768 && !isGrid ? 'center' : 'start'} flex-wrap`}>
      {
        filteredData.length > 0
          ?
          filteredData.map((item) => (
            <ProductCard key={item.id} item={item} openModal={openModal} isGrid={isGrid} productIdsObj={{ productIds, isProductIds }} />
          ))
          :
          <>
            <div className="col-12 mt-5 d-flex-center">
              <img src={emptyBox} alt="empty box" className='shopping-card-empty-box' />
              <p className="clr-dark fw-600 ff-0 fs-4 mn-13">Aucun Produits</p>
            </div>
            {/* <div className="col-12 d-flex justify-content-center align-items-center col-">
            </div> */}
          </>
      }
      {
        !marqueId && !categorieId ?
          <div className="col-12 d-flex justify-content-center">
            <>
              {filteredData.length > 0 && filteredData.length < totalProducts && !isFetchingNextPage && (
                <button className="btn-loading mt-5 d-none" onClick={fetchNextPage} >
                  Chargement Plus
                </button>
              )}
              {
                isFetchingNextPage && (
                  <>
                    < LoadingMore />
                  </>
                )
              }
            </>
          </div>
          :
          <div className="col-12 d-flex justify-content-center">
            <>
              {isLoading && !loadingMoreData && filteredData.length < totalByMarque && !categorieId && (
                <button className="btn-loading mt-5" onClick={handleByMarqueIncrement} >
                  Chargement Plus
                </button>
              )}
              {
                loadingMoreData && !categorieId && (
                  <>
                    < LoadingMore />
                  </>
                )
              }
            </>
          </div>
      }

      {
        !categorieId && !marqueId ?
          <div className="col-12 d-flex justify-content-center">
            <>
              {filteredData.length > 0 && filteredData.length < totalProducts && !isFetchingNextPage && (
                <button className="btn-loading mt-5" onClick={fetchNextPage} >
                  Chargement Plus
                </button>
              )}
              {
                isFetchingNextPage && (
                  <>
                    {/* < LoadingMore /> */}
                  </>
                )
              }
            </>
          </div>
          :
          <div className="col-12 d-flex justify-content-center">
            <>
              {!loadingMoreData && filteredData.length < totalByCategory && !marqueId && (
                <button className="btn-loading mt-5" onClick={handleByCategoryIncrement} >
                  Chargement Plus
                </button>
              )}
              {
                loadingMoreData && !marqueId && (
                  <>
                    < LoadingMore />
                  </>
                )
              }
            </>
          </div>
      }
    </div>
  );

  // useEffect(() => {
  //   const handleScroll = () => {
  //     const scrollPosition = window.innerHeight + window.pageYOffset;
  //     const pageHeight = document.documentElement.scrollHeight;

  //     if (scrollPosition >= pageHeight - 20) {
  //       if (filteredData.length > 0 && filteredData.length < totalProducts && !isFetchingNextPage) {
  //         if (fetchNextPage) {
  //           fetchNextPage();
  //         }
  //       }
  //     }
  //   };

  //   window.addEventListener('scroll', handleScroll);
  //   return () => {
  //     window.removeEventListener('scroll', handleScroll);
  //   };
  // }, [fetchNextPage, filteredData.length, isFetchingNextPage, totalProducts]);

  const OPTIONS: EmblaOptionsType = { loop: true }
  return (
    <>
      <Helmet>
        <title>HBeauty - Produits</title>
      </Helmet>
      <TopHeader />
      <Navbar />
      {
        marqueId ?

          <TopImage slug={marqueId} url='marque-by-slug' upload='marque_images' className='mb-5' />
          :
          categorieId ?

            !isSub ?
              <>
                <TopImage slug={parentSlug} url='categorie-by-slug' upload='category_images' className='' />
                <CircularMultiCarousel routeUrl='find-sub-categories' slug={parentSlug} uploadUrl='category_images' />
              </>
              :
              <CircularMultiCarousel routeUrl='find-sub-categories' slug={parentSlug} uploadUrl='category_images' />
            :
            // <CategorieLogoCarousel />
            // <CategorySwiper brands={categories}/>
            <CategoryEmblaCarousel categories={categories} options={OPTIONS} />
      }

      <div className={`boutique ${isGrid ? 'isgrid' : 'isrow'}`}>
        <div className="product-constainere containerr filter- boutique- container d-flexjustify-content-center">
          <div className="row d-flex justify-content-center">
            {
              products && (
                <div className="col-xl-3 col-lg-4 col-md-4 col-sm-11 col-11 mt-2 mb-4 pb-4 filter">
                  <div className={`container ${isFilter && windowWidth < 768 ? '' : 'mt-5'}`}>

                    <div className={`row ${isFilter && windowWidth < 768 ? 'd-none' : ''}`}>

                      {/* Start Result Filter */}
                      <div className="col-xl-12 col-lg-12 col-md-12 col-12 ">
                        <div className="filter-title filter-sort">
                          <span className='filter-result' >Filtrer le résultat:</span>
                          <select value={sortBy} className='form-control select-option' onChange={handleSortChange}>
                            {/* <option value=""></option> */}
                            <option value="default">Défaut</option>
                            <option value="prix-croissant">Prix croissant</option>
                            <option value="prix-decroissant">Prix décroissant</option>
                            <option value="date-asc">Ancienneté</option>
                            <option value="date-desc">Nouveauté</option>
                            {/* <option value="plus-recent-au-plus-ancien d-none">Plus récent au plus ancien</option> */}
                          </select>
                        </div>
                        <hr className='hr-tag mt-3' />
                      </div>
                      {/* End Result Filter */}

                      {/* Start Marques Filter */}
                      <div className="col-xl-12 col-lg-12 col-md-12 col-12 ">
                        <div className="filter-title filter-tri">
                          <span>MARQUES</span>
                          <div className="fil">
                            <div className="accordion" id="accordionExample">
                              <div className="accordion-item">
                                <h2 className="accordion-header">
                                  <button
                                    className="accordion-button"
                                    type="button"
                                    data-bs-toggle="collapse"
                                    data-bs-target={`#marquecollapse`}
                                    aria-expanded="false"
                                    aria-controls={`marquecollapse`}
                                  >
                                    Choisir une marque
                                  </button>
                                </h2>
                                {marques.map((item, index) => (
                                  <div
                                    key={index}
                                    id={`marquecollapse`}
                                    className="accordion-collapse collapse"
                                    data-bs-parent="#accordionExample"
                                  >
                                    <div className="accordion-body accordion-body-marque">
                                      {item && (
                                        <ul className="list-unstyled">
                                          <li key={item.id} onClick={() => setIsFilter(true)}>
                                            <Link className='nav-link'
                                              to={`/boutique/marque/${item.slug}`}>
                                              {/* to={`/boutique/marque/${item.slug}`}> */}
                                              {item.title}
                                            </Link>
                                          </li>
                                        </ul>
                                      )}
                                    </div>

                                  </div>
                                ))}
                              </div>
                            </div>
                          </div>
                        </div>
                        <hr className='hr-tag mt-3' />
                      </div>
                      {/* End Marques Filter */}

                      {/* Start Categories Filter */}
                      <div className="col-xl-12 col-lg-12 col-md-12 col-12 ">
                        <div className="filter-title filter-categories" >
                          <div onClick={() => setIsFilVisible(!isFilVisible)} className='c-pointer' >CATÉGORIES <MdExpandMore className='clr-dark fs-4 pb-1' /></div>
                          <div className={`fil ${isFilVisible ? '' : 'hidden'}`}>
                            <div className="accordion" id="accordionExample">
                              {categories.map((item, index) => (
                                <div className="accordion-item" key={index}>
                                  <h2 className="accordion-header">
                                    <button
                                      className="accordion-button"
                                      type="button"
                                      data-bs-toggle="collapse"
                                      data-bs-target={`#collapse${index}`}
                                      aria-expanded="false"
                                      aria-controls={`collapse${index}`}
                                    >
                                      {item.title}
                                    </button>
                                  </h2>
                                  <div
                                    id={`collapse${index}`}
                                    className="accordion-collapse collapse"
                                    data-bs-parent="#accordionExample"
                                  >
                                    <div className="accordion-body">
                                      {item.subCategory && (
                                        <ul className="list-unstyled">
                                          {item.subCategory.map((subitem, subindex) => (
                                            <li key={subindex} onClick={() => setIsFilter(true)}>
                                              <Link className='nav-link'
                                                to={`/boutique/categorie/${item.slug}/${subitem.slug}`}>
                                                {/* to={`/boutique/categorie/${subitem.slug}`}> */}
                                                {subitem.title}
                                              </Link>
                                            </li>
                                          ))}
                                        </ul>
                                      )}
                                    </div>

                                  </div>
                                </div>
                              ))}
                            </div>
                          </div>
                        </div>
                        <hr className='hr-tag mt-3' />
                      </div>
                      {/* End Categories Filter */}

                      {/* Start Price Filter */}
                      <div className="col-xl-12 col-lg-12 col-md-12 col-12 ">
                        <div className="filter-title filter-price">
                          FILTRER PAR TARIF
                        </div>
                        <div className="price-filter mt-4">
                          <div className="row">
                            <div className="col-12">
                              <Slider
                                getAriaLabel={() => 'Temperature range'}
                                value={filteredPrice}
                                onChange={handlePriceChange}
                                valueLabelDisplay="auto"
                                getAriaValueText={valueText}
                                max={maxPrice}
                                min={10}
                              />
                            </div>
                            <div className="col-12">
                              <div className="row">
                                <div className="col-8">
                                  <div className="price-values">
                                    <span className='filter-price-value'>PRIX : {filteredPrice[0]} DH <HiOutlineMinusSm /> {filteredPrice[1]} DH</span>
                                  </div>
                                </div>

                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      {/* End Price Filter */}

                    </div>

                    <div className={`row  ${isFilter ? 'm-filter m-filter-rowx' : 'm-filter-row-active'} ${windowWidth > 768 ? 'd-none' : ''}`}>
                      {!isFilter ? <hr className='hr-tag mt-3' /> : ''}
                      <div className={`col-6 text-start ${isFilter ? 'm-filter-icon' : 'm-filter-icon-active'}`}>

                        <button className='btn hb-small-btn hb-danger-btn me-2' onClick={() => setIsFilter(!isFilter)}>
                          <FaFilter className='hb-icon hb-danger-icon' />
                        </button>

                      </div>

                      <div className={`col-6 text-end fix-width ${windowWidth > 576 ? 'd-none' : ''} ${isFilter ? '' : 'm-grid-filter'}`}>
                        <button className='btn hb-small-btn hb-primary-btn me-2 ' onClick={() => setIsGrid(false)}>
                          <CiGrid2H className='hb-icon hb-primary-icon' />
                        </button>
                        <button className='btn hb-small-btn hb-primary-btn me-2' onClick={() => setIsGrid(true)}>
                          <BsGridFill className='hb-icon hb-primary-icon' />
                        </button>
                      </div>
                    </div>

                  </div>

                </div>
              )
            }

            {
              !marqueId && !categorieId ?
                (
                  reactQueryLoadingData ?
                    (
                      <div className="col-xl-9 col-lg-8 col-md-8 col-sm-12 col-12  loading-data">
                        <span className='loading-data-text text-center clr-dark ff-0 fw-600 fs-5 mt-2'>Chargement des produits...</span>
                        <LoadingData />
                      </div>
                    )
                    :
                    (
                      productContent
                    )
                )
                :
                ''
            }

            {
              marqueId || categorieId ?
                (
                  isLoadingData ?
                    (
                      <div className="col-xl-9 col-lg-8 col-md-8 col-sm-12 col-12  loading-data">
                        <span className='loading-data-text text-center clr-dark ff-0 fw-600 fs-5 mt-2'>Chargement des produits...</span>
                        <LoadingData />
                      </div>
                    )
                    :
                    (
                      productContent
                    )

                )
                :
                ''
            }

          </div>
        </div>
      </div>

      <Footer />
      {showModal && <Modal show={showModal} onClose={closeModal} product={selectedProduct} />}
    </>
  );
};

export default Boutique;