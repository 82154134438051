import React, { useEffect, useState } from 'react';
import Navbar from '../../components/Navbar/Navbar';
import TopHeader from '../../components/TopHeader/TopHeader';
import HeaderContainer from '../../components/HeaderContainer/HeaderContainer';
import Footer from '../../components/Footer/Footer';
import logo from '../../../assets/images/contact/communicate.png'
import facebook from '../../../assets/images/contact/facebook.png'
import instagram from '../../../assets/images/contact/instagram.png'
import * as yup from "yup";
import { useFormik } from "formik";
import InputBox from '../../components/InputBox/InputBox';
import SubmitButton from '../../components/SubmitButton/SubmitButton';
import { Helmet } from 'react-helmet-async';
import { FormatDate, apiUrl } from '../../helpers/ApiRequest';
import { Link, useNavigate } from 'react-router-dom';
import { FrCustomeErrorNorify, InfoNotify, notify } from '../../helpers/Toastify';
import DottedLoading from '../../components/Loading/DottedLoading';
import { AuthUserProps } from '../../helpers/Props';
import './ContactStyle.css'

import { CiFacebook } from 'react-icons/ci'
import { FaInstagram } from 'react-icons/fa'
import { GrContactInfo, GrContact } from 'react-icons/gr'
import { MdWhatsapp } from 'react-icons/md'
import { BsTelephone, BsChatLeftText, BsInstagram } from 'react-icons/bs'
import { RiInstagramFill } from 'react-icons/ri';
import { FaFacebookF, FaFacebookSquare, FaInstagramSquare } from 'react-icons/fa';
import useApiRequest from '@mbs-dev/api-request';


const Contact: React.FC = () => {

    const { apiRequest } = useApiRequest();
    const navigate = useNavigate();
    const user_token = localStorage.getItem('hb_user_token');
    const storedUser = localStorage.getItem('hb_user');
    const [user, setUser] = useState<AuthUserProps | null>(null);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        if (storedUser) {
            setUser(JSON.parse(storedUser));
        }

    }, [user_token, storedUser, navigate]);


    const validationSchema = yup.object({
        name: yup.string().required("Veuillez saisir votre nom"),
        email: yup.string().email('Email invalide').required('Veuillez saisir votre adresse e-mail'),
        subject: yup.string().required("Veuillez saisir votre sujet"),
        message: yup.string().required("Veuillez saisir votre message"),
    });

    const formik = useFormik({
        initialValues: {
            name: "",
            email: "",
            subject: "",
            message: "",
        },
        validationSchema: validationSchema,
        onSubmit: async (values, { resetForm }) => {


            if (user?.id) {
                const userId = `api/users/${user?.id}`;
                const data = { ...values, user: userId, dateCreate: FormatDate(new Date()), getResponse: 0 }

                setIsLoading(true)
                const response = await apiRequest({
                    route: `${apiUrl}/user_emails`,
                    method: 'POST',
                    data: data,
                    requiresAuth: true,
                    token: `${localStorage.getItem('hb_user_token')}`
                });

                if (response.status === 201) {
                    notify('Message envoyé');
                    setIsLoading(false);
                    resetForm()
                } else {
                    FrCustomeErrorNorify()
                    setIsLoading(false);
                }
            } else {
                InfoNotify('Vous devrez être connecté pour effectuer cette action!');
                setIsLoading(false);
            }

        },
    });

    const {
        touched,
        errors,
        values,
        handleChange,
        handleBlur,
        handleSubmit,
    } = formik;

    return (
        <>
            <Helmet>
                <title>Assistance HBeauty</title>
            </Helmet>
            <TopHeader />
            <Navbar />
            {/* <HeaderContainer
                className="fw-bold mt-5 contact-title"
                title='CONTACT'
            /> */}

            <div className="contact-top mt-3 ox-hidden">
                <div className="contact-top-image">

                </div>

                <div className="contact-top-cards d-flex-center">
                    <div className="row d-flex-center">

                        <div className="col-lg-4 col-md-4 col-sm-6 col-12 d-flex-center flex-wrap contact-top-cards-item">

                            <div className="w-100 text-center cards-item-parent">
                                < MdWhatsapp className='cards-item-icon' />
                            </div>

                            <div className="w-100 text-center cards-item-text">
                                <p className='ff-0 fw-600'>Par WhatsApp</p>
                            </div>

                            <div className="w-100 text-center cards-item-value d-flex-center">
                                <Link to="https://wa.me/212662533003" className='d-flex-center nav-link btn cards-item-btn ff-0 fw-600'>Contacter</Link>
                            </div>

                        </div>

                        <div className="col-lg-4 col-md-4 col-sm-6 col-12 d-flex-center flex-wrap contact-top-cards-item">

                            <div className="w-100 text-center cards-item-parent">
                                < BsTelephone className='cards-item-icon' />
                            </div>

                            <div className="w-100 text-center cards-item-text">
                                <p className='ff-0 fw-600'>Par Téléphone</p>
                            </div>

                            <div className="w-100 text-center cards-item-value d-flex-center">
                                <Link to="tel:0662533003" className='d-flex-center nav-link cards-item-tel ff-0 fw-600'>0662533003</Link>
                            </div>

                        </div>

                        <div className="col-lg-4 col-md-4 col-sm-6 col-12 d-flex-center flex-wrap contact-top-cards-item">

                            <div className="w-100 text-center cards-item-parent">
                                < BsChatLeftText className='cards-item-icon' />
                            </div>

                            <div className="w-100 text-center cards-item-text">
                                <p className='ff-0 fw-600'>Sur nos réseaux sociaux</p>
                            </div>

                            <div className="w-100 text-center cards-item-value d-flex-center">
                                <div className="sociall d-inline-flex">
                                    <Link to='https://www.facebook.com/people/HBeauty/100051215415162/' className='nav-link'>
                                        <CiFacebook className='cards-item-social-icon cards-item-social-icon-fb' />
                                        {/* <img src={facebook} alt="facebook" className='cards-item-social-image' /> */}
                                    </Link>
                                    <Link to='https://www.instagram.com/hbeauty_maroc/' className='nav-link'>
                                        <BsInstagram className='cards-item-social-icon' />
                                        {/* <img src={instagram} alt="instagram" className='cards-item-social-image' /> */}
                                    </Link>
                                </div>
                            </div>

                        </div>


                    </div>

                </div>
            </div>

            <div className="container contact-container d-flex-center pt-5 ox-hidden">

                <form action="" onSubmit={handleSubmit} className='d-flex-center'>
                    <div className="contact-form">
                        <div className="row d-flex justify-content-start align-items-center">

                            <div className="col-12 col-md-12 col-lg-12 col-xl-12 mb-4">
                                <InputBox
                                    label="Nom Complet"
                                    spanValue="Nom Complet"
                                    name="name"
                                    value={values.name}
                                    className={touched.name && errors.name ? "is-invalid" : ""}
                                    touched={touched}
                                    errors={errors}
                                    handleChange={handleChange}
                                    handleBlur={handleBlur}
                                />
                            </div>

                            <div className="col-12 col-md-12 col-lg-12 col-xl-12 mb-4">
                                <InputBox
                                    label="Adresse E-Mail"
                                    spanValue="Adresse E-Mail"
                                    name="email"
                                    type="email"
                                    value={values.email}
                                    className={touched.email && errors.email ? "is-invalid" : ""}
                                    touched={touched}
                                    errors={errors}
                                    handleChange={handleChange}
                                    handleBlur={handleBlur}
                                />
                            </div>

                            <div className="col-12 col-md-12 col-lg-12 col-xl-12 mb-4">
                                <InputBox
                                    label="Sujet"
                                    spanValue="Sujet"
                                    name="subject"
                                    value={values.subject}
                                    className={touched.subject && errors.subject ? "is-invalid" : ""}
                                    touched={touched}
                                    errors={errors}
                                    handleChange={handleChange}
                                    handleBlur={handleBlur}
                                />
                            </div>

                            <div className="col-12 col-md-12 col-lg-12 col-xl-12 mb-4">
                                <InputBox
                                    label="Message"
                                    spanValue="Message"
                                    name="message"
                                    textarea={true}
                                    rows={6}
                                    value={values.message}
                                    className={touched.message && errors.message ? "is-invalid" : ""}
                                    touched={touched}
                                    errors={errors}
                                    handleChange={handleChange}
                                    handleBlur={handleBlur}
                                />
                            </div>

                            <div className="col-12 col-md-12 col-lg-12 col-xl-12 mt-2 d-flex justify-content-start align-items-center">
                                {
                                    isLoading ?
                                        <DottedLoading />
                                        :
                                        <SubmitButton
                                            className="mt-1 px-5"
                                            btnLabel="Envoyer"
                                        />
                                }
                            </div>

                        </div>

                    </div>
                </form>

            </div>

            <Footer />
        </>
    );
};

export default Contact;