import { FC, useContext, useEffect, useState } from 'react';
import useApiRequest from '@mbs-dev/api-request';
import { WindowWidthContext } from '../../contexts/WindowWidthProvider';
import { FrCustomeErrorNorify } from '../../helpers/Toastify';
import { TopImageProps } from '../../helpers/Props';
import './TopImage.css'
import { apiUrl } from '../../helpers/ApiRequest';

const TopImage: FC<TopImageProps> = ({ className, url, upload, slug }) => {
    const [data, setData] = useState<any>();
    const { apiRequest } = useApiRequest();
    const { windowWidth } = useContext(WindowWidthContext)

    const fetchData = async () => {
        try {
            const response = await apiRequest({
                route: `${apiUrl}/${url}/${slug}`,
                method: 'GET'
            });

            if (response.status === 200) {
                setData(response.data[0]);
            }

        } catch (error) {
            FrCustomeErrorNorify()
        }
    };

    useEffect(() => {
        fetchData()
    }, [slug])


    return (
        <>
            {
                data ?


                    <div className={`top-image-container row align-items-center justify-content-center ${className}`}>
                        <div className="row align-items-center justify-content-between h-100">

                            <div className="col-xl-5 col-lg-5 col-md-12 col-sm-12 col-12 ps-5 d-flex align-items-center justify-content-center pe-4 h-100">
                                <div className="top-image h-100 d-flex align-items-center">
                                    <img
                                        src={`${process.env.REACT_APP_API_UPLOAD}/${upload}/${data?.image}`}
                                        className={` top-image-img h-100`}
                                        alt={`${data?.title}`} />
                                </div>

                            </div>

                            {
                                windowWidth < 992
                                    ?
                                    <hr className='hr-tag' />
                                    :
                                    ''
                            }

                            <div className="col-xl-7 col-lg-7 col-md-12 col-sm-12 col-12 top-image-desc-col">
                                <div className="d-non align-items-start justify-content-start mb-5">
                                    <h2 className='top-image-desc ff-0 fw-600'>{data?.title}</h2>
                                </div>
                                <div className="d-non align-items-center justify-content-center">
                                    <h5 className={`top-image-desc ff-3 fw-600 ${windowWidth < 992 ? 'pb-3' : ''}`}>{data?.description}</h5>
                                </div>
                            </div>

                        </div>
                    </div>

                    :
                    ''
            }

        </>
    );
};

export default TopImage;